<template>
    <div class="min-h-screen flex flex-col">
        <header class="bg-white border-b-1 border-blue-gray-100 z-1030 relative">
            <div v-if="is_admin" class="bg-blue-gray-900 text-white text-xs relative z-2 py-2 <sm:text-center">
                <div class="shell">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span v-html="$t('admin_access_shortcut', { username: current_user.firstname, href: '/admin' })"></span>
                </div>
            </div>

            <div class="shell flex items-center h-14 bg-white relative z-2">
                <div class="flex items-center justify-between w-full">
                    <div>
                        <localized-link :to="{name: 'home'}" class="logo"> carfull driver </localized-link>
                    </div>

                    <div>
                        <nav class="<md:hidden">
                            <ul class="flex items-center">
                                <template v-for="(item, index) in navItems">
                                    <li v-if="is_member" :key="index">
                                        <localized-link v-slot="{ isExactActive }" :to="{ name: item.path }" class="flex items-center font-semibold capitalize px-2 hover:text-blue-500">
                                            <span :class="{'text-blue-600': isExactActive}"> {{ $t(item.label) }} </span>
                                        </localized-link>
                                    </li>
                                </template>

                                <li class="pl-2">
                                    <v-button variant="primary" :to="{ name: 'booking' }"> {{ $t('action.book') }} </v-button>
                                </li>
                            </ul>
                        </nav>

                        <div class="flex items-center justify-end">
                            <v-language-switcher class="md:hidden" />

                            <v-button class="md:hidden ml-2" icon="menu" @click="extendNav = !extendNav" />
                        </div>
                    </div>
                </div>
            </div>

            <nav class="absolute left-0 w-full shadow bg-white transition-all duration-400 md:hidden" :class="{ 'top-full': extendNav, '-top-full': !extendNav }">
                <ul class="flex flex-col items-start">
                    <template v-for="(item, index) in navItems">
                        <li v-if="is_member" :key="index" class="py-3 px-2 w-full">
                            <localized-link v-slot="{ isExactActive }" :to="{ name: item.path }" class="flex items-center font-semibold capitalize px-2 hover:text-blue-500">
                                <span :class="{'text-blue-600': isExactActive}"> {{ $t(item.label) }} </span>
                            </localized-link>
                        </li>
                    </template>

                    <li class="pl-2 py-3 px-2 w-full border-t-1">
                        <v-button variant="primary" class="w-full" :to="{ name: 'booking' }"> {{ $t('action.book') }} </v-button>
                    </li>
                </ul>
            </nav>
        </header>

        <main>
            <header v-if="title || $hasSlot('header-actions')" :class="{'py-4': title}">
                <div class="shell">
                    <div class="flex items-center justify-between <md:flex-col">
                        <div class="flex items-center <md:flex-col">
                            <v-heading v-if="title" level="h1" class="mr-2 <md:mb-3 <md:mr-0"> {{ title }} </v-heading>

                            <slot v-if="$hasSlot('title-meta')" name="title-meta" />
                        </div>

                        <slot name="header-actions" />
                    </div>
                </div>
            </header>

            <slot />
        </main>

        <footer id="main-footer" class="mt-auto py-2">
            <div class="shell flex items-center justify-between">
                <nav class="uppercase text-xs">
                    <ul class="flex flex-wrap items-center -mx-4 <sm:justify-center">
                        <li class="px-4 py-1">
                            <localized-link :to="{ name: 'cgu' }" class="hover:underline"> {{ $t('cgu') }} </localized-link>
                        </li>
                    
                        <li v-if="main_contact" class="px-4 py-1">
                            <a :href="`https://wa.me/${$trim(main_contact.mobile)}`" target="_blank" class="hover:underline">
                                {{ $t('action.contact_us') }}
                            </a>
                        </li>

                        <li class="px-4 py-1">
                            <localized-link :to="{ name: 'booking' }" class="hover:underline"> {{ $t('action.book') }} </localized-link>
                        </li>
                    </ul>
                </nav>

                <v-language-switcher class="<sm:hidden" />
            </div>
        </footer>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'frontofficeLayout',

    props: {
        title: {
            type: String,
            default: null
        }
    },

    data: () => ({
        extendNav: false,
        navItems: [{
            label: 'my_profile',
            path: 'member-profile'
        }, {
            label: 'my_bookings',
            path: 'member-bookings'
        }]
    }),

    computed: {
        ...mapGetters('auth', [
            'is_login',
            'is_admin',
            'is_member',
            'current_user',
            'main_contact'
        ]),
    },

    mounted () {
        if (this.is_login) this.getCurrentUser();
        this.getMainContact();
    },

    methods: {
        ...mapActions('auth', [
            'getCurrentUser',
            'getMainContact'
        ])
    }
}
</script>

<style lang="scss">
.h-screen--with-header {
    height: calc(100vh - 56px);
}

.min-h-screen--with-header {
    min-height: calc(100vh - 56px);
}

.logo {
    font-family: 'Titillium Web';
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem;
}

.shell {
    @apply container mx-auto w-full p-0 <lg:px-4 <lg:max-w-none;
}
</style>