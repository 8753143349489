<template>
    <textarea :id="id" class="block w-full px-3 py-2 text-xs rounded bg-blue-gray-50 border-blue-gray-200 resize-none focus:shadow focus:border-0" v-bind="$attrs" :value="modelValue" rows="5" @input="$emit('update:modelValue', $event.target.value)"></textarea>
</template>

<script>
export default {
    name: "vFormTextarea",

    inject: ['id'],

    props: {
        /**
         * Value of the input
         */
        modelValue: {
            type: String,
            default: null
        }
    },

    emits: ['update:modelValue'],
}
</script>