import router from '@/router';

import { app } from '@/main'

import map from './map';
import dayjs from './dayjs';

export default () => {
    app.config.globalProperties.$id = () => Math.random().toString(36).substr(2, 9);

    app.config.globalProperties.$hasSlot = function(name) { return !!this.$slots[name]; }

    app.config.globalProperties.$trim = value => { 
        if (value) return value.replace(/\s/g,'');
    }

    app.config.globalProperties.$fallback = function(value, options = {}) {
        if (!value) return this.$t('undefined');

        let formattedValue = value;

        switch (options.type) {
            case 'date':
                formattedValue =  this.$dayjs(value).format('DD MMM YYYY - HH:mm');
                break;
            case 'tel':
                formattedValue = `<a href="tel:${value}" class="hover:text-blue-500 hover:underline"> ${value} </a>`;
                break;
            case 'email':
                formattedValue = `<a href="mailto:${value}" class="hover:text-blue-500 hover:underline"> ${value} </a>`;
                break;
            case 'price':
                formattedValue = `${value}€`;
                break;
            default:
                formattedValue = value;
        }

        return formattedValue;
    }

    app.config.globalProperties.$currentDevice = () => {
        let currentDevice = 'desktop';

        if (window.innerWidth <= 640) {
            currentDevice = 'mobile'
        } else if (window.innerWidth < 1024) {
            currentDevice = 'tablet'
        } else {
            currentDevice = 'desktop'
        }

        return currentDevice;
    }

    app.config.globalProperties.$redirect = (name, params = {}) => {        
        router.push({ name, params });
    }
    
    dayjs(app);

    map(app);
}
