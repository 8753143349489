import { app } from '@/main';

export default class Notify {
    constructor(response) {
        if (!response) return;
        
        switch (response.status) {
            case 200:
                if (response.data.message) this.new({message: `notification.success.${response.data.message}`, variant: 'success'});
                break;
            case 201:
                this.new({message: 'notification.success.created', variant: 'success'});
                break;
            case 202:
                this.new({message: 'notification.success.updated', variant: 'success'});
                break;
            case 401:
                this.new({message: 'notification.error.crendentials', variant: 'danger'});
                break;
            case 403:
                this.new({message: 'notification.error.not_allowed', variant: 'danger'});
                break;
            case 422:
                if (response.data.errors && response.data.errors.length) {
                    response.data.errors.forEach(error => {
                        this.new({message: error, variant: 'danger'});
                    });
                }

                if (response.data.message) this.new({message: response.data.message, variant: 'danger'});
                break;
            case 500:
                this.new({message: 'notification.error.internal_error', variant: 'danger'});
                break;
        }
    }

    new (options) {
        window.dispatchEvent(new CustomEvent('shown.notification', {'detail': {
            id: app.config.globalProperties.$id(),
            ...options
        }}));
    }
}
