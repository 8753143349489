<template>
    <section class="bg-blue-gray-200 min-h-screen flex items-center">
        <form class="max-w-144 w-full mx-auto rounded-lg bg-white p-8">
            <header class="text-center mb-10">
                <v-heading v-if="title" level="h3"> {{ title }} </v-heading>

                <v-heading v-if="subtitle" level="h6" class="text-blue-gray-500"> {{ subtitle }} </v-heading>
            </header>

            <slot />

            <footer class="mt-6">
                <slot name="footer" />
            </footer>
        </form>
    </section>
</template>

<script>
export default {
    name: 'authLayout',

    props: {
        /**
		 * Title
		 **/
		title: {
			type: String,
			default: null,
		},
		/**
		 * Subtitle
		*/
		subtitle: {
			type: String,
			default: null,
		},
    }
};
</script>
