<template>
    <component :is="marker" type="button" class="btn active:shadow leading-none" v-bind="$attrs" :class="[{'btn--outlined': outlined}, `btn--${variant}`]">
        <v-icon v-if="$hasSlot('default') && loading" name="loader" class="mr-2" />

        <v-icon v-if="icon" :name="icon" :class="{'mr-2': $hasSlot('default')}" />

        <slot />
    </component>
</template>

<script>
    export default {
        name: 'vButton',

        props: {
            /**
            * Name of button's icon
            **/
            icon: {
                type: String,
                default: null
            },
            /**
            * Set it to true to display loading icon
            */
            loading: {
                type: Boolean,
                default: false,
            },
            /**
            * The variant style of the button.
            */
            variant: {
                type: String,
                default: 'default',
                validator: value => value.match(/(default|primary|danger|success)/),
            },
            /**
            * Set it to true to appply outline style
            */
            outlined: {
                type: Boolean,
                default: false,
            }
        },

        data: () => ({
            classObject: {},
        }),
        
        computed: {
            marker () {
                let marker = 'button';

                if (this.$attrs.to) marker = 'localized-link';
                else if (this.$attrs.href) marker = 'a';

                return marker;
            }
        }
    };
</script>

<style lang="scss">
.btn {
    &:not(.btn--default) {
        @apply text-white min-h-10 min-w-40 rounded py-2 px-3 border capitalize flex items-center justify-center outline-none cursor-pointer transition duration-300 hover:active:bg-white;
    }

    &--primary {
        @apply bg-blue-gray-900 border-blue-gray-900 hover:bg-blue-gray-800 hover:active:text-blue-gray-800 hover:active:border-blue-gray-800;

        &.btn--outlined {
            @apply text-blue-gray-800;
        }
    }

    &--success {
        @apply bg-green-500 border-green-600 hover:bg-green-600 hover:active:text-green-600 hover:active:border-green-600;

        &.btn--outlined {
            @apply text-green-500;
        }
    }

    &--danger {
        @apply bg-red-500 border-red-600 hover:bg-red-600 hover:active:text-red-600 hover:active:border-red-600;

        &.btn--outlined {
            @apply text-red-500;
        }
    }

    &--outlined {
        @apply bg-white hover:text-white;
    }
}
</style>

