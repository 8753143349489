import loadJS from 'load-js';

import { tokens } from '@/assets/javascript/utils';

export default app => {
    app.config.globalProperties.$mapPromise = () => {
        return new Promise(resolve => {
            loadJS({
                url: `https://maps.googleapis.com/maps/api/js?libraries=places&key=${tokens.map.secret_key}`,
                async: true,
            }).then(() => {
                resolve();
            }).catch(error => {
                console.error('error', error);
            });
        });
    };

    app.provide('$mapPromise', app.config.globalProperties.$mapPromise);
}
