<template>
    <v-form-input ref="input" :placeholder="$t('action.enter_location')" :value="modelValue?.address" />
</template>

<script>
import axios from 'axios';
import { tokens } from '@/assets/javascript/utils';

export default {
    name: 'vFormPlaces',

    props: {
        /**
        * Value of the input
        */
        modelValue: {
            type: Object,
            default: () => {}
        }
    },

    emits: ['update:modelValue'],

    data: () => ({
        input: ' ',
        autocomplete: null,
        options: {
            fields: ['geometry', 'place_id', 'types'],
            strictBounds: true,
            componentRestrictions: {
                country: 'fr',
            },
        }
    }),

    mounted () {
        this.$mapPromise().then(() => {
            this.handleInit().then(() => {
                this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs.input.$el, this.options);

                window.google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
                    this.$emit('update:modelValue', {
                        address: this.$refs.input.$el.value,
                        ...this.autocomplete.getPlace()
                    });
                });
            });
        });
    },

    methods: {
        handleInit () {
            return new Promise(resolve => {
                delete axios.defaults.headers.common['Authorization'];

                axios.get(`https://maps.googleapis.com/maps/api/geocode/json?&address=ile de france&key=${tokens.map.secret_key}`).then(response => {
                    if (response.status !== 200 || !response.data.results.length) return;

                    const bounds = response.data.results[0].geometry.bounds;
                    const southwest = new window.google.maps.LatLng(bounds.southwest.lat, bounds.southwest.lng);
                    const northeast = new window.google.maps.LatLng(bounds.northeast.lat, bounds.northeast.lng);

                    this.options.bounds = new window.google.maps.LatLngBounds(southwest, northeast);

                    resolve();
                });
            });
        }
    }
};
</script>
