<template>
    <span class="capitalize py-2 px-3 text-xs font-medium rounded inline-block" :class="[tagClass]">
        <slot />
    </span>
</template>

<script>
export default {
    name: 'vTag',

    props: {
        /**
        * The variant style of the button.
        */
        variant: {
            type: String,
            default: 'success',
            validator: value => value.match(/(success|pending|danger)/),
        },
    },

    computed: {
        tagClass () {
            const variants = {
                success: ['bg-lime-100', 'text-lime-600'],
                pending: ['bg-yellow-100', 'text-yellow-600'],
                danger: ['bg-red-100', 'text-red-600']
            }

            return variants[this.variant].join(' ');
        }
    }
};
</script>
