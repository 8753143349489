<template>
    <div class="bg-white relative rounded-md shadow pointer-events-auto py-3 pl-4 pr-10 flex items-start transition-opacity duration-600" :class="{ 'opacity-0 visibility-hidden': !active }">
        <v-icon v-if="icon" :name="icon" size="sm" class="rounded-full mr-3 p-2" :class="{'bg-green-100 text-green-500': variant === 'success', 'bg-red-100 text-red-500': variant === 'danger'}" />

        <div>
            <v-heading level="h5"> {{ title }} </v-heading>
            <p class="text-sm"> <slot /> </p>
        </div>

        <v-button icon="close" class="absolute top-3 right-3 text-blue-gray-300" @click="handleClose" />
    </div>
</template>

<script>
export default {
	name: 'vAlert',
    
    props: {
        /**
		* The variant style of the alert.
        * @values info, success
        */
        variant: {
            type: String,
            default: 'success',
            validator: value => {
                return value.match(/(success|danger)/);
            },
        }
    },

    emits: ['close'],
    
    data: () => ({
        active: true,
    }),

    computed: {
        title () {
            let title;

            switch (this.variant) {
                case 'success':
                    title = this.$i18n.t('notification.success.title');
                    break;
                case 'danger':
                    title = this.$i18n.t('notification.error.title');
                    break;
            }

            return title;
        },

        icon () {
            let icon;

            switch (this.variant) {
                case 'success':
                    icon = 'check';
                    break;
                case 'danger':
                    icon = 'exclamation';
                    break;
            }

            return icon;
        }
    },

    mounted () {
        let duration = 4;

        let timer = setInterval(() => {
            duration = duration - 1

            if (duration == 0) {
                clearInterval(timer)
                    this.handleClose();
                }
        }, 1000)
    },

    methods: {
        handleClose () {
            this.active = false;

            setTimeout(() => {
                this.$emit('close', this.$attrs.id)
            }, 600);
        }
    }
}
</script>