<template>
    <component :is="level" :class="[level, {'block text-xs uppercase font-semibold text-blue-gray-600': level === 'h6'}]" v-bind="$attrs"> <slot /> </component>
</template>

<script>
export default {
    name: 'vHeading',

    props: {
    /**
    * The heading level used for the heading.
    * `h1, h2, h3, h4, h5`
    */
        level: {
            type: String,
            default: 'h1',
            validator: value => value.match(/(h1|h2|h3|h4|h5|h6)/),
        },
    },
};
</script>

<style lang="scss" scoped>

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: $font-primary;
}

.h1 {
    text-transform: capitalize;
    font-weight: 800;
    font-size: 28px;
    line-height: 1.3;
}

.h2 {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 24px;
}

.h3 {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 20px;
}

.h4 {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
}

.h5 {
    font-weight: 600;
    font-size: 14px;
}
</style>
