import ApiService from '@/services/api.service';

export default {
    namespaced: true,

    state: {
        bookings: [],
        booking: {}
    },

    getters: {
        bookings(state) {
            return state.bookings;
        },

        booking(state) {
            return state.booking;
        },
    },

    mutations: {
        SET_BOOKINGS(state, data) {
            state.bookings = data;
        },

        SET_BOOKING(state, data) {
            state.booking = data;
        }
    },

    actions: {
        async getBookings (store) {
            const response = await ApiService.get('me/bookings');

            store.commit('SET_BOOKINGS', response.data);

            return response;
        },

        async getBooking (store, id) {
            const response = await ApiService.get(`me/bookings/${id}`);

            store.commit('SET_BOOKING', response.data);

            return response;
        },

        async createBooking (store, params) {
            const response = await ApiService.post('me/bookings', { booking: params });

            return response;
        },

        async updateBooking (store, params) {
            const response = await ApiService.put(`me/bookings/${params.id}`, { booking: params });

            store.commit('SET_BOOKING', response.data);

            return response;
        },

        async previewBooking (store, params) {
            if (!params.pickup_address || !params.dropoff_address) return;

            const response = await ApiService.post('booking/preview', { booking: params });

            store.commit('SET_BOOKING', response.data);

            return response;
        }
    },
};
