import { createStore } from 'vuex';

import adminBookingsStore from '@/views/admin/bookings/store';
import adminCustomersStore from '@/views/admin/customers/store';

import memberBookingsStore from '@/views/member/bookings/store';

import authStore from '@/views/auth/store';

export default createStore({
    modules: {
        admin_bookings: adminBookingsStore,
        admin_customers: adminCustomersStore,
        member_bookings: memberBookingsStore,
        auth: authStore
    },
});
