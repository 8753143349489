<template>
    <article class="bg-white shadow-md rounded" :class="{'py-5 px-4': !hasPadding}">
        <v-heading v-if="title" level="h3" class="mb-3"> {{ title }} </v-heading>

        <slot />
    </article>
</template>

<script>
export default {
    name: 'vCard',

    props: {
        title: {
            type: String, 
            default: null
        }
    },

    computed: {
        hasPadding() {
            if (!this.$attrs.class) return;

            const padding = this.$attrs.class.indexOf('p-');

            return padding > -1;
        }
    }
};
</script>
