<template>
    <div>
        <router-view />

        <div v-if="notifications.length" class="fixed h-screen top-0 right-0 p-4 z-9999 overflow-auto max-w-100 w-full pointer-events-none">
            <v-alert v-for="notification in notifications" :id="notification.id" :key="notification.id" :variant="notification.variant" class="mb-2" @close="deleteNotification(notification.id)">
                {{ $t(notification.message) }}
            </v-alert>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => ({
            notifications: [],
        }),

        mounted () {
            window.addEventListener('shown.notification', this.createNotification);
        },  

        unmounted () {
            window.removeEventListener('shown.notification', this.createNotification);
        },

        methods: {
            createNotification (event) {
                this.notifications.push(event.detail);
            },

            deleteNotification (id) {
                const index = this.notifications.findIndex(notification => notification.id === id)

                this.notifications.splice(index, 1);
            }
        }
    };
</script>