import dayjs from 'dayjs';

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import utc from 'dayjs/plugin/utc';

require('dayjs/locale/fr');

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

export default app => {
    app.config.globalProperties.$dayjs = dayjs;

    app.provide('$dayjs', app.config.globalProperties.$dayjs);
}
