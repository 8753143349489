import ApiService from '@/services/api.service';

export default {
    namespaced: true,

    state: {
        bookings: [],
        booking: {}
    },

    getters: {
        bookings(state) {
            return state.bookings;
        },

        booking(state) {
            return state.booking;
        },
    },

    mutations: {
        SET_BOOKINGS(state, data) {
            state.bookings = data;
        },

        SET_BOOKING(state, data) {
            state.booking = data;
        }
    },

    actions: {
        async getBookings(store) {
            const response = await ApiService.get('admin/bookings');

            store.commit('SET_BOOKINGS', response.data);

            return response;
        },

        async getBooking(store, id) {
            const response = await ApiService.get(`admin/bookings/${id}`);

            store.commit('SET_BOOKING', response.data);

            return response;
        },

        async updateBooking (store, params) {
            const response = await ApiService.put(`admin/bookings/${params.id}`, { booking: params });

            store.commit('SET_BOOKING', response.data);

            return response;
        },
    },
};
