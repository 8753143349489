<template>
    <template v-if="$attrs.type === 'tel'">
        <vue-tel-input :id="id" ref="tel-input" :value="modelValue" v-bind="$attrs" :input-options="{placeholder: ''}" mode="international" @input="value => handleChange(value)" />
    </template>

    <template v-else>
        <input :id="id" v-bind="$attrs" class="w-full block bg-transparent rounded border bg-blue-gray-50 border-blue-gray-200 text-xs py-2.5 px-3 focus:shadow focus:border-blue-gray-300" :value="modelValue" @input="handleChange($event)">
    </template>
</template>

<script>
import { VueTelInput } from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';

export default {
    name: 'vFormInput',

    components: {
        VueTelInput
    },

    inject: ['id'],

    props: {
        /**
         * Value of the input
         */
        modelValue: {
            type: [String, Number],
            default: ''
        }
    },

    emits: ['update:modelValue'],

    methods: {
        handleChange (event) {
            const value = event.target ? event.target.value : event;

            this.$emit('update:modelValue', value);
        }
    }
}
</script>

<style lang="scss" scoped>
.vue-tel-input[type="tel"] {
    padding: 0 !important;
    border-radius: 0.25rem !important;
    @apply border-1 border-blue-gray-200 bg-blue-gray-50;

    :deep(.vti__input) {
        font-size: 12px;
        background: transparent;
        padding: 12px 12px !important;
        line-height: 1 !important;

        &:focus {
            box-shadow: none !important;
        }
    }

    :deep(.vti__dropdown) {
        .vti__dropdown-list {
            border-radius: 4px;
            margin: 8px 0;
            @apply border-blue-gray-200;

            &.below {
                top: 100% !important;
            }

            .vti__dropdown-item {
                padding-left: 7px;
                padding-right: 7px;
                font-size: 12px;
                display: flex;
                align-items: center;
                @apply text-blue-gray-800;
            }
        }
    }

    &:focus-within {
        box-shadow: 0 0 0 0.2rem rgba(#94a3b8, .2) !important;
        @apply border-blue-gray-300;
    }
}
</style>