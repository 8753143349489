<template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <i class="icon" :class="[`icon--${size}`]" v-html="svg" />
</template>

<script>
const req = require.context('@/assets/icons', true, /^\.\/.*\.svg$/);

export default {
    name: 'vIcon',

    props: {
        /**
        * The name of the icon to display.
        */
        name: {
            type: String,
            required: true,
            default: null,
        },
        /**
        * The fill color of the SVG icon.
        */
        fill: {
            type: String,
            default: 'currentColor',
        },
        /**
        * The size of the icon. Defaults to medium.
        */
        size: {
            type: String,
            default: 'md',
            validator: value => value.match(/(xs|sm|md|lg|xl)/),
        },
    },

    data() {
        return {
            svg: req(`./${this.name}.svg`).replace(/^<svg /, `<svg style="fill: ${this.fill}"`),
        };
    },
};
</script>

<style lang="scss">
.icon {
    display: inline-flex;

    &--xl {
        svg {
            width: 64px;
            height: 64px;
        }
    }

    &--lg {
        svg {
            width: 42px;
            height: 42px;
        }
    }

    &--md {
        svg {
            width: 22px;
            height: 22px;
        }
    }

    &--sm {
        svg {
            width: 18px;
            height: 18px;
        }
    }

    &--xs {
        svg {
            width: 12px;
            height: 12px;
        }
    }
}
</style>
