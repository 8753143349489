<template>
    <main class="flex pl-16 pt-16 transition-all" :class="{ '<md:pl-0': !extendNav }">
        <aside class="w-16 flex-shrink-0 bg-blue-100 min-h-screen fixed top-0 left-0 z-100 transition-all" :class="{ '<md:-left-full': !extendNav }">
            <nav class="py-4 flex flex-col h-screen">
                <ul class="flex flex-col items-center">
                    <li v-for="(item, idx) in navItems" :key="idx">
                        <localized-link v-slot="{ isExactActive }" :to="{name: item.href}" class="outline-none block flex flex-column">
                            <div class="w-9 h-9 rounded flex items-center justify-center mb-3" :class="[{'bg-white text-blue-400 hover:bg-blue-200': !isExactActive}, {'bg-blue-700 text-blue-300': isExactActive}]">
                                <v-icon v-if="item.icon" :name="item.icon" />
                            </div>
                        </localized-link>
                    </li>
                </ul>
            </nav>
        </aside>

        <header class="bg-white fixed top-0 left-0 w-full border-b-1 border-blue-gray-100 h-14 flex items-center py-2 pl-16" :class="{ '<md:pl-0': !extendNav }">
            <div class="px-6 w-full <md:px-4">
                <div class="py-2 h-14 flex items-center justify-between">
                    <div class="flex items-center">
                        <v-button class="mr-4 md:hidden" icon="menu" @click="extendNav = !extendNav" />

                        <v-heading level="h4">
                            {{ $t('welcome', { username: current_user.firstname }) }}
                        </v-heading>
                    </div>

                    <nav>
                        <ul class="flex items-center">
                            <li v-for="(item, index) in navUtils" :key="index" class="ml-4 text-blue-gray-400 hover:text-blue-500">
                                <v-button :to="{ name: item.href }">
                                    <v-icon :name="item.icon" />
                                </v-button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>

        <div class="w-full py-6 px-6 <md:px-4">
            <header class="pb-4">
                <div class="flex items-center justify-between <md:flex-col">
                    <div class="flex items-center">
                        <v-heading v-if="title" level="h1" class="mr-2 <md:mb-3"> {{ title }} </v-heading>

                        <slot name="title-meta" />
                    </div>

                    <slot name="header-actions" />
                </div>
            </header>

            <slot />
        </div>
    </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'backofficeLayout',

    props: {
        title: {
            type: String,
            default: null
        }
    },

    data: () => ({
        extendNav: false,
        navItems: [
            {
                name: 'Bookings',
                icon: 'bookings',
                href: 'admin-bookings'
			},
			{
                name: 'Customers',
                icon: 'customers',
                href: 'admin-customers'
            }
        ],

        navUtils: [
            {
                icon: 'exit',
                href: 'home'
			},
			{
                icon: 'account',
                href: 'admin-profile'
            }
        ],
	}),

    computed: {
        ...mapGetters('auth', [
            'current_user',
        ]),
    },

    mounted () {
        this.getCurrentUser();
    },

    methods: {
        ...mapActions('auth', [
            'getCurrentUser',
        ])
    }
}
</script>
