<template>
    <div class="checkbox">
        <input :id="id" type="checkbox" :checked="modelValue" @change="$emit('update:modelValue', $event.target.checked)" />

        <label :for="id"></label>
    </div>
</template>


<script>
export default {
    name: "vFormCheckbox",

    inject: ['id', 'setupFormComponents'],

    props: {
        /**
         * Value of the input
         */
        modelValue: {
            type: [Boolean, Number],
            default: false
        },
    },

    emits: ['update:modelValue'],

    created () {
        this.setupFormComponents({ inline: true });
    },

}
</script>

<style lang="scss" scoped>
.checkbox {
    input {
        position: absolute;
        opacity: 0;
        visibility: hidden;

        &:checked {
            & + label {
                &::before {
                    border: none;
                    @apply bg-color-blue-gray-400;
                }

                &:after {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        & + label {
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;

            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                border-radius: 2px;
                margin-right: 8px;
                transition: all .5s;
                @apply border-1 border-blue-gray-300 bg-blue-gray-200;
            }

            &:after {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background: #FFF;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 5px;
                margin: auto;
                border-radius: 50%;
                opacity: 0;
                visibility: hidden;
                transition: all .5s;
            }
        }
    }
}
</style>
