<template>
    <div class="flex relative" :class="[{'flex-col': !inline}, {'flex-row-reverse items-center': inline}]">
        <label v-if="label" :id="$id()" class="block text-xs text-blue-gray-500 capitalize font-600 text-left" :class="[{'mb-1': !inline}]">
            {{ label }}
        </label>

        <slot />

        <div v-if="v.$errors || $hasSlot('helper')" class="flex item-center justify-between">
            <ul>
                <li v-for="error in v.$errors" :key="error.$uid" class="text-red-600 my-1 text-xs">
                    {{ $t(`validation.${error.$params.type || error.$validator}`, error.$params) }}
                </li>
            </ul>

            <div v-if="$hasSlot('helper')" class="my-1 text-right text-xs w-full">
                <slot name="helper" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'vFormGroup',

    provide() {
        return {
            id: this.$id(),
            setupFormComponents: ({ inline }) => {
                this.inline = inline;
            },
        };
    },

    props: {
        /**
        * Name of the label
        */
        label: {
            type: String,
            default: null,
        },
        /**
        * Validation object
        */
        v: {
            type: Object,
            default: () => ({}),
        },
    },

    data: () => ({
        inline: false,
    }),
};
</script>
