<template>
    <div ref="dropdown" class="relative" @mouseover="handleOpen" @mouseleave="handleClose">
        <v-button class="flex items-center" @click.stop="handleToggle">
            <div class="flex items-center">
                <slot name="button-content"> {{ text }} </slot>
            </div>

            <v-icon v-if="!noCaret" name="caret-down" />
        </v-button>

        <div class="absolute top-full py-1 min-w-44 left-0" :class="{'right-0 left-auto': right, 'hidden': !shown, 'right-0 transform -translate-x-1/2': center}" :style="{width: `${width}px`}">
            <ul role="menu" class="bg-white shadow">
                <slot />
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'vDropdown',

    props: {
        /**
         * Specify width for drodown menu
         */
        width: {
            type: [String, Number],
            default: null
        },

        /**
		 * If set to true, the menu will display on hover
		 * @values true, false
		**/
        hover: {
            type: Boolean,
            default: false
        },

        /**
         * Set it to true to align dropdown menu to the right
         * @values true, false
         */
        right: {
            type: Boolean,
            default: false
        },

        /**
         * Set it to true to align dropdown menu to the center
         * @values true, false
         */
        center: {
            type: Boolean,
            default: false
        },

        noCaret: {
            type: Boolean,
            default: false
        }

    },

    data: () => ({
        shown: false,
    }),

    methods: {
        handleToggle () {
            this.shown = !this.shown;
        },

        handleOpen () {
            if(this.hover) this.shown = true;
        },

        handleClose () {
            this.shown = false;
        }
    }
};
</script>
