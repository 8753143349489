<template>
    <div class="relative bg-ink flex items-center justify-center inline-block bg-amber-500 overflow-hidden relative w-10 h-10 flex-shrink-0" :class="{ 'rounded-full': rounded, 'rounded': !rounded }">
        <span class="uppercase text-white font-800"> {{ alt.charAt(0) }} </span>
    </div>
</template>

<script>
export default {
	name: 'vAvatar',

	props: {
		/**
		 * The fallback text of the avatar.
		 */
		alt: {
			type: String,
			default: 'undefined',
		},

		rounded: {
			type: Boolean,
			default: false,
		},
	},
}
</script>
