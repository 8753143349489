import profileRoutes from './profile/router';
import bookingsRoutes from './bookings/router';

export default [
    {
        path: '/profil',
        name: 'member-home',
        component: () => import('./index.vue'),
        meta: { 
            guard: 'member'
        },
        children: [
            ...profileRoutes,
            ...bookingsRoutes,
        ]
    }
];
