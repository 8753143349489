import ApiService from '@/services/api.service';
import { tokenService } from '@/services/token.service';

export default {
    namespaced: true,

    state: {
        current_user: {},
        main_contact: {},
        is_login: null
    },

    getters: {
        current_user (state) {
            return state.current_user;
        },

        main_contact (state) {
            return state.main_contact;
        },

        is_login (state) {
            return state.is_login || tokenService.get();
        },

        is_admin (state) {
            if (!state.current_user.id) return false;  

            return state.current_user?.roles?.find(el => el.name === 'admin' || el.name === 'superadmin');
        },

        is_member (state) {
            if (!state.current_user.id) return false;

            return state.current_user?.roles?.find(el => el.name === 'member');
        }
    },

    mutations: {
        SET_IS_LOGIN(state) {
            state.is_login = tokenService.get();
        },

        SET_CURRENT_USER(state, data) {
            state.current_user = data;
        },

        SET_MAIN_CONTACT(state, data) {
            state.main_contact = data;
        },
    },

    actions: {
        async login (store, params) {
            const response = await ApiService.post('login', { user: params });

            tokenService.set(response.headers.authorization);

            ApiService.setHeader();

            store.commit('SET_IS_LOGIN');
        },

        async signup (store, params) {
            await ApiService.post('signup', { user: params });
        },

        async logout (store) {
            await ApiService.delete('logout', { auth_token: tokenService.get() });

            tokenService.delete();

            ApiService.removeHeader();

            store.commit('SET_CURRENT_USER', {});
        },

        async getCurrentUser (store) {
            const response = await ApiService.get('me');

            store.commit('SET_CURRENT_USER', response.data);

            return response;
        },

        async updateCurrentUser (store, params) {
            const response = await ApiService.put('me', { user: params });

            store.commit('SET_CURRENT_USER', response.data);

            return response;
        },

        async getMainContact (store) {
            const response = await ApiService.get('contact');

            store.commit('SET_MAIN_CONTACT', response.data);

            return response;
        },

        async forgotPassword (store, params) {
            const response = await ApiService.post('password/forgot', { user: params });

            return response;
        },

        async resetPassword (store, params) {
            const response = await ApiService.post('password/reset', { user: params });

            return response;
        }
    },
};