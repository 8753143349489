import { app } from '@/main'

import vClickOutside from 'click-outside-vue3';

import modal from '@/directives/modal';
import confirm from '@/directives/confirm';

export default () => {
    app.use(vClickOutside);
    app.directive('modal', modal);
    app.directive('confirm', confirm);
};
