import { createWebHistory } from 'vue-router';

import { i18n } from 'vue-lang-router';

import { createLangRouter } from 'vue-lang-router';
import translations from '@/lang/translations';
import localizedURLs from '@/lang/localized-urls';

import { domain, defaultLocale } from '@/assets/javascript/utils';

import { guardRedirection } from './helpers'

import dayjs from 'dayjs';

import adminRoutes from '@/views/admin/router';
import memberRoutes from '@/views/member/router';
import authRoutes from '@/views/auth/router';
import visitorRoutes from '@/views/visitor/router';

const router = createLangRouter({
	defaultLanguage: defaultLocale,
	translations,
	localizedURLs,
}, {
    history: createWebHistory(process.env.BASE_URL),
    base: process.env.BASE_URL,
    routes: [
        ...adminRoutes,
        ...memberRoutes,
        ...authRoutes,
        ...visitorRoutes 
    ]   
});

router.beforeEach((to, from, next) => {
    const locale = i18n.global.locale;

    let title, description;
    
    ['title', 'description'].forEach(meta => {
        const key = i18n.global.te(`seo.${to.name}.${meta}`) ? to.name : 'home';

        switch (meta) {
            case 'title':
                title = i18n.global.t(`seo.${key}.${meta}`);
                break;
            case 'description':
                description = i18n.global.t(`seo.${key}.${meta}`);
                break;
        }
    });
    
    document.title = `${process.env.VUE_APP_TITLE} | ${title}`;
    document.head.querySelector('meta[name="description"]').content = description;
    document.head.querySelector('link[rel="canonical"]').href = `${domain}/${locale}`;

    dayjs.locale(locale);

    guardRedirection(to.meta?.guard).then(() => {
        next();
    }).catch(path => {
        next({ name: path });
    });
});

export default router;