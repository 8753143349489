<template>
    <div ref="marker" class="flex items-center justify-center bg-gray-900 absolute rounded text-sm text-white py-1 px-2">
        <v-icon name="star" class="text-white" size="sm" />
        <slot />
    </div>
</template>

<script>
export default {
    name: 'vMarker',

    provide() {

        // const { bounds } = useMapsHelper();

        const lat = parseFloat(this.marker.geometry.location.lat());
        const lng = parseFloat(this.marker.geometry.location.lng());

        // const vnode = this;

        return this.$mapPromise().then(() => {
            var marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(lat, lng),
                title:"Hello World!"
            });

            // To add the marker to the map, call setMap();
            marker.setMap(this.map);
        //     if (!bounds.value) {
        //         bounds.value = new window.google.maps.LatLngBounds();
        //     }

        //     bounds.value.extend(new window.google.maps.LatLng(lat, lng));

        //     this.map.fitBounds(bounds.value);

        //     class Overlay extends window.google.maps.OverlayView {
        //         constructor(map) {
        //             super();

        //             this.container = vnode.$refs.marker;
        //             this.setMap(map);
        //         }

        //         draw() {
        //             let pos = this.getProjection().fromLatLngToDivPixel(new window.google.maps.LatLng(lat, lng));

        //             this.container.style.left = `${pos.x}px`;
        //             this.container.style.top = `${pos.y}px`;
        //         }

        //         onAdd() {
        //             const panes = this.getPanes();
        //             panes.floatPane.appendChild(this.container);
        //         }

        //         onRemove() {
        //             this.container.remove();
        //         }
        //     }

        //     this.$overlay = new Overlay(this.map);
        });
    },

    props: {
        map: {
            type: Object,
            default: () => {},
            required: true,
        },

        marker: {
            type: Object,
            default: () => {},
            required: true,
        }
    },

    unmounted () {
        this.$overlay.setMap(null);
        this.$overlay = undefined;
    },
};
</script>
