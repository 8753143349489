export default [
    {
        path: 'reservations',
        name: 'member-bookings',
        component: () => import('./index.vue'),
    }, {
        path: 'reservations/:id',
        name: 'member-booking-detail',
        component: () => import('./detail.vue'),
    }
];
