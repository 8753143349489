import axios from 'axios';

import { tokenService } from '@/services/token.service';
import Notify from '@/services/notifications.service';

axios.interceptors.response.use(response => {    
    new Notify(response);

    return response;
}, error => {
    new Notify(error.response);

    return Promise.reject(error.response);
});

const apiService = {
    setHeader() {
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = tokenService.get();
    },

    removeHeader() {
        axios.defaults.headers.common['Authorization'] = null;
    },

    get(resource) {
        this.setHeader();

        return axios.get(`${process.env.VUE_APP_API_BASE_URL}/${resource}`);
    },

    post(resource, params) {
        this.setHeader();

        return axios.post(`${process.env.VUE_APP_API_BASE_URL}/${resource}`, params);
    },

    put(resource, params) {
        this.setHeader();

        return axios.put(`${process.env.VUE_APP_API_BASE_URL}/${resource}`, params);
    },

    delete(resource) {
        this.setHeader();
        
        return axios.delete(`${process.env.VUE_APP_API_BASE_URL}/${resource}`);
    },
};

export default apiService;
