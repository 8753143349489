import profileRoutes from './profile/router';
import bookingsRoutes from './bookings/router';
import customersRoutes from './customers/router';

export default [
    {
        path: '/admin',
        name: 'admin-home',
        component: () => import('./index.vue'),
        meta: { 
            guard: 'admin'
        },
        children: [
        ...profileRoutes,
        ...bookingsRoutes,
        ...customersRoutes]
    }
];
