import { createApp } from 'vue';

import App from './App.vue';

import './registerServiceWorker';

import router from '@/router';
import store from '@/store';
import system from '@/system';
import { i18n } from 'vue-lang-router';

import 'windi.css';
import '@/assets/stylesheets/main.scss';

import helpers from "@/assets/javascript/main.js";
import directives from "@/directives";

export const app = createApp(App);

app.use(store);

app.use(router);

app.use(system);

app.use(i18n);

helpers();

directives();

app.mount('#app');
