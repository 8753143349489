<template>
    <ul v-if="links.length" class="flex items-center">
        <li v-for="(link, index) in links" :key="link.langIndex" class="mr-3">
            <a :href="link.url" class="uppercase flex items-center text-sm"> 
                <span v-if="index > 0" class="mr-2"> | </span> 
                <span :class="{ 'font-semibold': link.activeClass }"> {{ link.langIndex }} </span>
            </a>
        </li>
    </ul>
</template>

<script>
import { i18n } from 'vue-lang-router';

import translations from '@/lang/translations';

export default {
	name: 'vLanguageSwitcher',

    data() {
        return {
            locale: i18n.global.locale,
            currentUrl: this.$router.currentRoute._value.fullPath
        };
    },

     

    computed: {
        links () {
            const links = [];

            for (var lang in translations) {
                links.push({
                    activeClass: (lang == this.locale ? 'active' : ''),
                    langIndex: lang,
                    langName: translations[lang].name || lang,
                    url: this.$localizePath(this.currentUrl, lang),
                });
            }
            
            return links;
        }
    }
}
</script>
