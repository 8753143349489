import store from '@/store';

export const guardRedirection = guard => {
    return new Promise((resolve, reject) => {
        switch (guard) {
            case 'admin':
                if (store.getters['auth/is_login']) {
                    store.dispatch('auth/getCurrentUser').then(() => {
                        if (store.getters['auth/is_admin']) resolve()
                        else reject('home');
                    });
                } else reject('login');

                break;
            case 'member':
                if (store.getters['auth/is_login']) {
                    store.dispatch('auth/getCurrentUser').then(() => {
                        if (store.getters['auth/is_member']) resolve()
                        else reject('home');
                    });
                } else reject('login');

                break;
            case 'outsider':
                if (store.getters['auth/is_login']) reject('home');
                else resolve();

                break;
            default:
                resolve();     
                break;
        }
    });
}