<template>
    <li role="presentation" class="px-3 py-2 hover:bg-blue-50">
        <router-link :to="{ name: $attrs.href }" class="block capitalize">
            <slot />
        </router-link>
    </li>
</template>

<script>
/**
 * @example [none]
 */

export default {
    name: 'vDropdownItem',
};
</script>
