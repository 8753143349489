export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/visitor/home/index.vue'),          
    },
    {
        path: '/reserver',
        name: 'booking',
        component: () => import('./booking/index.vue'),
    }, {
        path: '/conditions-d-utilisation',
        name: 'cgu',
        component: () => import('./cgu/index.vue'),
    }, {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('./404/index.vue'),
    }
];
