<template>
    <div ref="root" class="h-full"> </div>
</template>

<script>
import _ from 'lodash';
import { ref, watch, reactive, toRefs, onMounted, inject } from 'vue';
import { mapStyles } from '@/assets/javascript/utils';

export default {
    name: 'vMap',

    props: {
        origin: {
            type: Object,
            default: () => {}
        },

        destination: {
            type: Object,
            default: () => {}
        },

        waypoints: {
            type: Array,
            default: () => []
        }
    },

    emits: ['change'],

    setup (props) {
        const mapPromise = inject('$mapPromise');

        const root = ref(null);

        const markers = [];

        let directionsService, directionsRenderer;

        const state = reactive({
            map: new Object
        });

        onMounted(() => {
            mapPromise().then(() => {
                state.map = new window.google.maps.Map(root.value, {
                    center: { lat: 48.856614, lng: 2.3522219 },
                    zoom: 18,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    zoomControl: false,
                    streetViewControl: false,
                    styles: mapStyles,
                });

                directionsService = new window.google.maps.DirectionsService();
                directionsRenderer = new window.google.maps.DirectionsRenderer({
                    suppressMarkers: true,
                    polylineOptions: {
                        strokeColor: '#1e293b'
                    }
                });

                directionsRenderer.setMap(state.map);
            });
        });

        watch(() => _.cloneDeep([ref(props.origin), ref(props.destination), ref(props.waypoints)]), () => {
            if (!Object.keys(props.origin).length || !Object.keys(props.destination).length ) return;

            const waypoints = [];

            props.waypoints.forEach(waypoint => {
                if (!waypoint.address) return;

                waypoints.push({
                    location: {
                        query: waypoint.address
                    },
                    stopover: true
                })
            });

            markers.forEach(marker => {                
                marker.setMap(null);
            });

            markers.length = 0;

            [props.origin, ...props.waypoints, props.destination].filter(value => Object.keys(value).length !== 0).forEach(place => {
                const marker = new window.google.maps.Marker({
                    position: place.geometry.location,
                    title: place.address,
                    map: state.map,
                    icon: {
                        url: require('@/assets/images/pin.svg'),
                        scaledSize: new window.google.maps.Size(32, 32)
                    }
                });

                markers.push(marker);
            });

            directionsService.route({
                origin: props.origin.address,
                destination: props.destination.address,
                waypoints: waypoints,
                travelMode: 'DRIVING'
            }, response => {
                try {
                    directionsRenderer.setDirections(response);
                } catch (error) {
                    console.error(error);
                }
            });
        });

        return {
            root,
            ...toRefs(state),
        }
    },
};
</script>
