<template>
    <div>
        <v-form-group v-if="searchOptions.enabled" class="grid grid-cols-1 mb-4">
            <v-form-input v-model="search" type="search" :placeholder="searchOptions.placeholder" class="mb-3" />
        </v-form-group>

        <div class="w-full overflow-y-auto">
            <table class="w-full min-w-120">
                <thead>
                    <tr>
                        <th v-for="(column, index) in columns" :key="index" class="pb-2 px-4 text-left text-xs text-gray-400 uppercase" :style="{width: column.width}">
                            {{ $t(`label.${column.label}`) }}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <template v-if="filteredRows.length">
                        <tr v-for="(row, index) in filteredRows.slice(currentPage - 1, limit * currentPage)" :key="index" class="cursor-pointer hover:bg-blue-gray-100" @click="$emit('on-cell-click', { row, column, index, $event })">
                            <td v-for="(column, i) in columns" :key="i" class="py-3 px-4 text-left">
                                <slot name="table-row" :row="row" :column="column" :formattedRow="formattedRow(row)" :index="index">
                                    <template v-if="column.type === 'tag'">
                                        <v-tag v-if="row[column.field]" :variant="formattedRow(row)[column.field].variant">
                                            {{ $t(formattedRow(row)[column.field].name) }}
                                        </v-tag>
                                    </template>

                                    <template v-else-if="column.type === 'tel' || column.type === 'email'">
                                        <!-- eslint-disable-next-line vue/no-v-html -->
                                        <span @click="$event.stopPropagation()" v-html="formattedRow(row)[column.field]"></span>
                                    </template>

                                    <template v-else>
                                        {{ formattedRow(row)[column.field] }}
                                    </template>
                                </slot>
                            </td>
                        </tr>
                    </template>

                    <template v-else>
                        <td :colspan="columns.length + 1" class="py-2 text-center">
                            {{ $t('no_data') }}
                        </td>
                    </template>
                </tbody>
            </table>
        </div>
    
        <ul v-if="filteredRows.length / limit > 1" class="flex mt-3 justify-end items-center flex-wrap">
            <li v-for="index in (filteredRows.length / limit)" :key="index">
                <button class="border px-3 py-1" :class="{'ml-1': index > 1, 'text-amber-500': index === currentPage, 'hover:text-amber-300': index !== currentPage}" @click="currentPage = index">
                    {{ index }}
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
	name: 'vTable',

	props: {
        columns: {
            type: Array,
            default: () => [],
            required: true
        },

        rows: {
            type: Array,
            default: () => [],
            required: true
        },

        searchOptions: {
            type: Object,
            default: () => ({
                enabled: false,
                placeholder: 'Search this table'
            })
        }
	},

    emits: ['on-cell-click'],

    data: () => ({
        search: '',
        limit: 20,
        currentPage: 1,
    }),

    computed: {
        filteredRows () {
            const filteredRows = this.rows.filter(row => {
                const values = Object.values(row).reduce((arr, value) => {
                    const formatted = val => {
                        if (!val) return;

                        if (typeof val === 'object') Object.values(val).forEach(v => { formatted(v) });
                        else if (!arr.includes(val)) arr.push(val);
                    }

                    formatted(value);

                    return arr;
                }, []);
                
                const formatted = str => str.toString().toLowerCase();

                return values.filter(Boolean).filter(value => formatted(value).indexOf(formatted(this.search)) > -1).length;
            });

            return filteredRows;
        }
    },

    watch: {
        filteredRows () {
            this.currentPage = 1;
        }
    },

    methods: {
        formattedRow (row) {
            const formattedRow = {};

            this.columns.forEach(column => {
                formattedRow[column.field] = this.$fallback(row[column.field], { type: column.type });
            });

            return formattedRow;
        }
    }
}
</script>
