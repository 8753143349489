export default [
    {
        path: 'clients',
        name: 'admin-customers',
        component: () => import('./index.vue'),
    }, {
        path: 'clients/:id',
        name: 'admin-customer-detail',
        component: () => import('./detail.vue'),
    }
];
