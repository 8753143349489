<template>
    <date-picker :id="id" :model-value="modelValue" :locale="locale" v-bind="$attrs" @update:model-value="event => $emit('update:modelValue', event)">
        <template #default="{ inputEvents }">
            <v-form-input v-model="value" v-on="inputEvents" />
        </template>
    </date-picker>
</template>

<script>
import { i18n } from 'vue-lang-router';

import 'v-calendar/dist/style.css';
import { DatePicker } from 'v-calendar';

export default {
    name: 'vFormCalendar',
    
    components: {
        DatePicker,
    },

    inject: ['id'],

    props: {
        /**
        * Value of the input
        */
        modelValue: {
            type: Object,
            default: new Date(),
        }
    },

    emits: ['update:modelValue'],
    
    computed: {
        locale () {
            return i18n.global.locale
        },

        value () {
            const format = this.$attrs.mode === 'time' ? 'HH:mm' : 'DD MMM YYYY';

            return this.$dayjs(this.modelValue).format(format);
        }
    }
};
</script>

<style lang="scss">
.vc-popover-content-wrapper {
    .vc-highlight {
        @apply bg-blue-500;
    }

    .vc-time-picker {
        .vc-time-date {
            display: none;
        }

        .vc-time-select {
            .vc-select {
                select {
                    background-image: none;
                }
            }
        }
    }
}
</style>