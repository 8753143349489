export default [
    {
        path: '/connexion',
        name: 'login',
        meta: { 
            guard: 'outsider'
        },
        component: () => import('./login.vue'),
    },
    {
        path: '/inscription',
        name: 'signup',
        meta: { 
            guard: 'outsider'
        },
        component: () => import('./signup.vue'),
    },
    {
        path: '/mot-de-passe/oublie',
        name: 'forgot-password',
        meta: { 
            guard: 'outsider'
        },
        component: () => import('./password_forgot.vue'),
    },
    {
        path: '/mot-de-passe/reinitialiser',
        name: 'reset-password',
        meta: { 
            guard: 'outsider'
        },
        component: () => import('./password_reset.vue'),
    }
];
