import ApiService from '@/services/api.service';

export default {
    namespaced: true,

    state: {
        customers: [],
        customer: {}
    },

    getters: {
        customers(state) {
            return state.customers;
        },

        customer(state) {
            return state.customer;
        },
    },

    mutations: {
        SET_CUSTOMERS(state, data) {
            state.customers = data;
        },

        SET_CUSTOMER(state, data) {
            state.customer = data;
        }
    },

    actions: {
        async getCustomers(store) {
            const response = await ApiService.get('admin/users');

            store.commit('SET_CUSTOMERS', response.data);

            return response;
        },

        async getCustomer(store, id) {
            const response = await ApiService.get(`admin/users/${id}`);

            store.commit('SET_CUSTOMER', response.data);

            return response;
        }
    },
};
