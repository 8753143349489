<template>
    <vue-multiselect v-bind="$attrs" :placeholder="$t(`action.${placeholder}`)" :options="_options" :model-value="modelValue" @update:model-value="value => $emit('input', value)">
        <template #caret>
            <div class="multiselect__select text-blue-gray-600 mr-2">
                <v-icon name="chevron-down" />
            </div>
        </template>
    </vue-multiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect';

export default {
    name: 'vFormSelect',

    components: {
        VueMultiselect
    },

    inject: ['id'],

    props: {
        /**
        * Value of the input
        */
        modelValue: {
            type: [Object, Array, String, Number],
            default: () => {}
        },

        options: {
            type: Array,
            default: () => [],
            required: true,
        },

        placeholder: {
            type: String,
            default: 'select_option'
        },

        clearable: {
            type: Boolean,
            default: false
        }
    },

    emits: ['input'],

    computed: {
        _options () {
            const _options = this.options || [];

            if (this.clearable) {
                const defaultOption = {}
                defaultOption.id = null;
                defaultOption[this.$attrs.label] = `--- ${this.$t(`action.${this.placeholder}`)} ---`;

                _options.unshift(defaultOption);
            }

            return _options;
        }
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
.multiselect  {
    min-height: auto;

    &.multiselect--above {
        .multiselect__content-wrapper {
            top: auto;
            bottom: 0;
        }
    }

    .multiselect__select {
        height: 100%;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &:before {
            display: none;
        }
    }

    .multiselect__tags {
        border-width: 1px;
        border-color: #e2e8f0;
        background-color: #f8fafc;
        border-radius: .25rem;
        padding: .55rem .75rem;
        font-size: 0.75rem;
        min-height: auto;
        box-shadow: none;
        height: 100%;

        .multiselect__placeholder {
            color: #6e7582;
            margin-bottom: 0;
            padding-top: 0;
        }

        .multiselect__single {
            padding: 0;
            margin: 0;
            background: transparent;
            font-size: 0.75rem;
        }

        .multiselect__input {
            opacity: 0;
        }
    }

    .multiselect__content-wrapper {
        padding: 12px;
        top: 0;
        background: #FFF;
        border: 0 solid transparent;
        border-radius: 3px;
        box-shadow: 0 1px 8px rgb(229, 231, 235);

        .multiselect__content {
            border: 0 solid transparent;

            .multiselect__option {
                font-family: $font-primary;
                font-weight: 400;
                font-size: 14px;
                line-height: 1;

                &--disabled {
                    background: transparent !important;
                }
            }

            .multiselect__element {
                .multiselect__option {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-transform: capitalize;
                    padding: 8px 12px;
                    min-height: auto;

                    &:after {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 6px;
                        border-left: 4px solid transparent;
                        border-bottom: 4px solid transparent;
                        transform: rotate(-45deg);
                        padding: 0;
                        position: relative;
                        top: -2px;
                        background: transparent;
                    }

                    &.multiselect__option--selected {
                        pointer-events: none;
                        background: transparent;

                        &:after {
                            border-color: $blue-500;
                        }

                        &.multiselect__option--highlight {
                            &:after {
                                border-color: $blue-500;
                            }
                        }
                    }

                    &.multiselect__option--highlight {
                        background: $blue-500;
                        color: #FFF;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}
</style>
