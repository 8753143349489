import jwt_decode from 'jwt-decode';

const KEY = 'user-token';

export const tokenService = {
    get () {
        const token = localStorage.getItem(KEY);

        if (token && jwt_decode(token).exp < Date.now() / 1000) tokenService.delete();

        return token;
    },

    set (token) {
        return localStorage.setItem(KEY, token);
    },

    delete () {
        return localStorage.removeItem(KEY);
    }
};

export const token = tokenService.get();
