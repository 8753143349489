export default (el, binding) => {
    let modifier = Object.keys(binding.modifiers);

    if (el.hasAttribute('href')) modifier = el.getAttribute('href').split('#')[1];

    if (!modifier) return;

    el.onclick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const target = document.getElementById(modifier);

        if (!target) {
            console.error(`Target with ID "${modifier}" is missing`);
            return;
        }

        target.dispatchEvent(new CustomEvent('modal-shown'));
    };
};
