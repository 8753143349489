import { createApp, h } from 'vue';

import { app } from '@/main';

const insert = async (el, binding) => {
    const container = document.createElement('div');
    document.body.appendChild(container);

    const component = (await import('@/components/Modal')).default

    const instance = createApp({
        render () {
            return h(component, {
                modelValue: true,
                title: binding.instance.$t('confirm.title'), 
                onClose() {
                    container.remove();
                },
                onConfirm() {
                    el.dispatchEvent(new Event('confirm'));
                }
            }, {
                default: () => h('div', binding.value)
            });
        }
    });

    Object.assign(instance._context, app._context);

    instance.mount(container);
}

export default (el, binding) => {    
    el.onclick = () => {
        insert(el, binding);
    };
};
