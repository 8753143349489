<template>
    <div :id="$id()" ref="modal" v-bind="$attrs" class="fixed z-9999 top-0 left-0 w-full h-screen cursor-pointer bg-gray-800 bg-opacity-40 pt-28 px-3" :class="{'opacity-0 invisible': !active}" @click.self="handleClose">
        <div class="bg-white border-gray-200 relative rounded-lg mx-auto w-full flex flex-col max-h-145" :class="sizingClass">
            <div class="text-center pt-6 pb-2 px-4 flex-shrink-0">
                <template v-if="variant == 'success'">
                    <v-icon name="verified" size="xl" class="text-green-500 mb-1" />
                </template>

                <template v-else-if="variant == 'error'">
                    <v-icon name="error" size="xl" class="text-red-500 mb-1" />
                </template>

                <v-heading v-if="title" level="h3"> {{ title }} </v-heading>

                <v-heading v-if="subtitle" level="h6" class="text-blue-gray-500"> {{ subtitle }} </v-heading>
            </div>

            <div class="max-h-1\/2 pt-6 pb-4 px-4 text-center text-base overflow-auto">
                <slot />
            </div>

            <footer class="flex flex-col items-center justify-center pb-6 pt-2 px-4 flex-shrink-0">
                <slot name="footer" :confirm="handleConfirm">
                    <v-button variant="primary" class="mx-1" outlined @click.prevent="handleClose">
                        {{ $t('action.abort') }}
                    </v-button>

                    <v-button variant="primary" class="mx-1" @click.prevent="handleConfirm">
                        {{ $t('action.continue') }}
                    </v-button>
                </slot>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
	name: 'vModal',

	props: {
		/**
		 * Whether the modal is opened or not
		 */
		modelValue: {
			type: Boolean,
			default: false,
		},
		/**
		 * The size of the modal. Defaults to medium.
		*/
		size: {
			type: String,
			default: 'md',
			validator: value => {
				return value.match(/(sm|md|lg)/)
			},
        },
        /**
		 * Title of the modal
		 **/
		title: {
			type: String,
			default: null,
		},
		/**
		 * Subtitle of the modal
		*/
		subtitle: {
			type: String,
			default: null,
		},
		/**
        * The variant style of the button.
        */
        variant: {
            type: String,
            default: null,
            validator: value => value.match(/(success|error)/),
        }
    },

    emits: ['update:modelValue', 'close', 'confirm'],

	data() {
		return {
			active: this.modelValue,
		}
	},

	computed: {
		sizingClass () {
            let style;

			switch (this.size) {
				case 'sm':
					style = 'max-w-130';
					break;
				case 'md':
					style = 'max-w-200';
					break
				case 'lg':
					style = 'max-w-288';
					break;
			}

            return style;
        }
	},

	watch: {
        modelValue () {
            if (this.modelValue) this.handleShown();
            else this.handleClose();
        },
    },

	mounted () {
        this.$refs.modal.addEventListener('modal-shown', this.handleShown);
    },

    beforeUnmount () {
        this.$refs.modal.removeEventListener('modal-shown', this.handleShown);
    },

	methods: {
		handleShown () {
            this.active = true;

            document.body.style.overflow = 'hidden';

            this.$emit('update:modelValue', this.active);
        },

        handleClose () {
            this.active = false;

            this.$emit('close');

            document.body.style.removeProperty('overflow');

            this.$emit('update:modelValue', this.active);
        },

		handleConfirm () {
            this.$emit('confirm');

            this.handleClose();
        }
	}
}
</script>
